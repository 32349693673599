<template lang="html">
  <section class="v-home-navbar">
    <Toolbar class="p-mb-4 col-12">
      <template #left>
        <Button
          label="Nuevo"
          icon="pi pi-plus"
          class="p-button-success p-mr-2"
          @click="
            item = {
              xlabel: '',
              xvisible: false,
              xicon: '',
              xto: '',
              xsuperItemid: null,
            };
            itemDialog = true;
          "
        />
        <Button
          label="Borrar"
          icon="pi pi-trash"
          class="p-button-danger ml-2"
          :disabled="!selectedItems || !selectedItems.length"
          @click="borrarDialog = true"
        />
      </template>
      <template #right> </template>
    </Toolbar>

    <DataTable
      :value="navbar"
      :paginator="true"
      class="p-datatable-customers"
      :expandedRows.sync="filasExpandidas"
      :rows="10"
      :resizableColumns="true"
      columnResizeMode="fit"
      dataKey="xnavItemid"
      :rowHover="true"
      :selection.sync="selectedItems"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    >
      <template #header>
        <div class="table-header">
          Lista de items del navbar
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['global']"
              placeholder="Búsqueda global"
            />
          </span>
        </div>
      </template>
      <Column :expander="true" headerStyle="width: 3rem" />
      <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
      <Column header="Id">
        <template #body="slotProps">
          {{ slotProps.data.xnavItemid }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters['xnavItemid']"
            class="p-column-filter"
            placeholder="ID"
            style="width: 5em !important"
          />
        </template>
      </Column>

      <Column header="Label">
        <template #body="slotProps">
          {{ slotProps.data.xlabel }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters['xlabel']"
            class="p-column-filter"
            placeholder="/label"
            style="width: 5em !important"
          />
        </template>
      </Column>

      <Column header="Enlace">
        <template #body="slotProps">
          {{ slotProps.data.xto }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters['xto']"
            class="p-column-filter"
            placeholder="/to"
            style="width: 5em !important"
          />
        </template>
      </Column>

      <Column header="Icono">
        <template #body="slotProps">
          <i :class="slotProps.data.xicon"></i>
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters['xsuperItemid']"
            class="p-column-filter"
            placeholder="pi pi-home"
            style="width: 5em !important"
          />
        </template>
      </Column>

      <Column field="xvisible" header="Visible" sortable>
        <template #body="slotProps">
          <vs-switch v-model="slotProps.data.xvisible" class="w-50" disabled />
        </template>
      </Column>

      <!--BOTONES COLUMN-->
      <Column
        headerStyle="width: 4rem; text-align: center"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <vs-button
            icon
            @click="
              item = slotProps.data;
              itemDialog = true;
            "
          >
            <i class="pi pi-cog"></i>
          </vs-button>
          <vs-button
            icon
            color="danger"
            @click="
              borrarDialog = true;
              selectedItems = [slotProps.data];
            "
          >
            <i class="pi pi-trash"></i>
          </vs-button>
        </template>
      </Column>

      <!-- EXPAND  -  EXPAND  -  EXPAND  -  EXPAND  -  EXPAND  -  EXPAND-->
      <template #expansion="slotProps">
        <h5 class="text-xl d-inline">Items anidados</h5>
        <DataTable
          :value="slotProps.data.items"
          :expandedRows.sync="filasExpandidas2"
          :paginator="true"
          class="p-datatable-customers"
          :rows="10"
          :resizableColumns="true"
          columnResizeMode="fit"
          dataKey="xnavItemid"
          :rowHover="true"
          :selection.sync="selectedItems"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 25, 50]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <Column :expander="true" headerStyle="width: 3rem" />
          <Column header="Id">
            <template #body="slotProps">
              {{ slotProps.data.xnavItemid }}
            </template>
            <template #filter>
              <InputText
                type="text"
                v-model="filters['xnavItemid']"
                class="p-column-filter"
                placeholder="ID"
                style="width: 5em !important"
              />
            </template>
          </Column>

          <Column header="Id Padre">
            <template #body="slotProps">
              {{ slotProps.data.xsuperItemid }}
            </template>
            <template #filter>
              <InputText
                type="text"
                v-model="filters['xsuperItemid']"
                class="p-column-filter"
                placeholder="ID Padre"
                style="width: 5em !important"
              />
            </template>
          </Column>

          <Column header="Label">
            <template #body="slotProps">
              {{ slotProps.data.xlabel }}
            </template>
            <template #filter>
              <InputText
                type="text"
                v-model="filters['xlabel']"
                class="p-column-filter"
                placeholder="/label"
                style="width: 5em !important"
              />
            </template>
          </Column>

          <Column header="Enlace">
            <template #body="slotProps">
              {{ slotProps.data.xto }}
            </template>
            <template #filter>
              <InputText
                type="text"
                v-model="filters['xto']"
                class="p-column-filter"
                placeholder="/to"
                style="width: 5em !important"
              />
            </template>
          </Column>

          <Column header="Icono">
            <template #body="slotProps">
              <i :class="slotProps.data.xicon"></i>
            </template>
            <template #filter>
              <InputText
                type="text"
                v-model="filters['xsuperItemid']"
                class="p-column-filter"
                placeholder="pi pi-home"
                style="width: 5em !important"
              />
            </template>
          </Column>

          <Column field="xvisible" header="Visible" sortable>
            <template #body="slotProps">
              <vs-switch
                v-model="slotProps.data.xvisible"
                class="w-50"
                disabled
              />
            </template>
          </Column>

          <!--BOTONES COLUMN-->
          <Column
            headerStyle="width: 4rem; text-align: center"
            bodyStyle="text-align: center; overflow: visible"
          >
            <template #body="slotProps">
              <vs-button
                icon
                @click="
                  item = slotProps.data;
                  itemDialog = true;
                "
              >
                <i class="pi pi-cog"></i>
              </vs-button>
              <vs-button
                icon
                color="danger"
                @click="
                  borrarDialog = true;
                  selectedItems = [slotProps.data];
                "
              >
                <i class="pi pi-trash"></i>
              </vs-button>
            </template>
          </Column>

          <template #empty>
            <h5>No hay nada por aquí</h5>
            <vs-button @click="getItems">
              <span><i class="pi pi-refresh"></i> Recargar</span>
            </vs-button>
          </template>
          <template #expansion="slotProps">
            <h5 class="text-xl d-inline">Items anidados</h5>
            <DataTable
              :value="slotProps.data.items"
              :expandedRows.sync="filasExpandidas2"
              :paginator="true"
              class="p-datatable-customers"
              :rows="10"
              :resizableColumns="true"
              columnResizeMode="fit"
              dataKey="xnavItemid"
              :rowHover="true"
              :selection.sync="selectedItems"
              :filters="filters"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              :rowsPerPageOptions="[10, 25, 50]"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            >
              <Column header="Id">
                <template #body="slotProps">
                  {{ slotProps.data.xnavItemid }}
                </template>
                <template #filter>
                  <InputText
                    type="text"
                    v-model="filters['xnavItemid']"
                    class="p-column-filter"
                    placeholder="ID"
                    style="width: 5em !important"
                  />
                </template>
              </Column>

              <Column header="Id Padre">
                <template #body="slotProps">
                  {{ slotProps.data.xsuperItemid }}
                </template>
                <template #filter>
                  <InputText
                    type="text"
                    v-model="filters['xsuperItemid']"
                    class="p-column-filter"
                    placeholder="ID Padre"
                    style="width: 5em !important"
                  />
                </template>
              </Column>

              <Column header="Label">
                <template #body="slotProps">
                  {{ slotProps.data.xlabel }}
                </template>
                <template #filter>
                  <InputText
                    type="text"
                    v-model="filters['xlabel']"
                    class="p-column-filter"
                    placeholder="/label"
                    style="width: 5em !important"
                  />
                </template>
              </Column>

              <Column header="Enlace">
                <template #body="slotProps">
                  {{ slotProps.data.xto }}
                </template>
                <template #filter>
                  <InputText
                    type="text"
                    v-model="filters['xto']"
                    class="p-column-filter"
                    placeholder="/to"
                    style="width: 5em !important"
                  />
                </template>
              </Column>

              <Column header="Icono">
                <template #body="slotProps">
                  <i :class="slotProps.data.xicon"></i>
                </template>
                <template #filter>
                  <InputText
                    type="text"
                    v-model="filters['xsuperItemid']"
                    class="p-column-filter"
                    placeholder="pi pi-home"
                    style="width: 5em !important"
                  />
                </template>
              </Column>

              <Column field="xvisible" header="Visible" sortable>
                <template #body="slotProps">
                  <vs-switch
                    v-model="slotProps.data.xvisible"
                    class="w-50"
                    disabled
                  />
                </template>
              </Column>

              <!--BOTONES COLUMN-->
              <Column
                headerStyle="width: 4rem; text-align: center"
                bodyStyle="text-align: center; overflow: visible"
              >
                <template #body="slotProps">
                  <vs-button
                    icon
                    @click="
                      item = slotProps.data;
                      itemDialog = true;
                    "
                  >
                    <i class="pi pi-cog"></i>
                  </vs-button>
                  <vs-button
                    icon
                    color="danger"
                    @click="
                      borrarDialog = true;
                      selectedItems = [slotProps.data];
                    "
                  >
                    <i class="pi pi-trash"></i>
                  </vs-button>
                </template>
              </Column>

              <template #empty>
                <h5>No hay nada por aquí</h5>
                <vs-button @click="getItems">
                  <span><i class="pi pi-refresh"></i> Recargar</span>
                </vs-button>
              </template>
            </DataTable>
          </template>
        </DataTable>
      </template>
    </DataTable>

    <Dialog
      :visible.sync="borrarDialog"
      :style="{ width: '450px' }"
      header="Confirmar"
      :modal="true"
    >
      <div class="d-flex flex-column">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span> ¿Seguro que quieres borrar estos items? </span>
        <ul>
          <li v-for="item in selectedItems" :key="item.xnavItemid">
            {{ item.xnavItemid }} - {{ item.xlabel }}
          </li>
        </ul>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text text-white"
          @click="borrarDialog = false"
        />
        <Button
          label="Sí"
          icon="pi pi-check"
          class="p-button-danger"
          @click="borrarItems()"
        />
      </template>
    </Dialog>
    <Dialog
      :visible.sync="itemDialog"
      :header="'Nav item ' + item.xlabel"
      :modal="true"
      class="p-fluid col-12 col-md-8"
      :baseZIndex="21001"
    >
      <div
        @submit.prevent=""
        class="p-fluid d-flex flex-wrap ml-auto mr-auto p-1 col-12 col-lg-8 justify-content-center align-content-between"
      >
        <div class="col-12">
          <div class="p-field col-12">
            <label for="nombre">Label</label>
            <InputText id="nombre" type="text" v-model="item.xlabel" />
          </div>
          <div class="p-field p-col-12 mt-3">
            <label for="enlace">Enlace</label>
            <Dropdown
              v-model="item.xto"
              :options="enlaces"
              optionLabel="xto"
              optionValue="xto"
              :editable="true"
              :filter="true"
              placeholder="Elige un enlace"
              :showClear="true"
              @before-show="getEnlaces"
            >
              <template #option="slotProps">
                <div>
                  <div>
                    {{ slotProps.option.xtitulo }} - {{ slotProps.option.xto }}
                  </div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="p-field p-col-12 mt-3">
            <label for="enlace">Item padre</label>
            <Dropdown
              v-model="item.xsuperItemid"
              :options="navItemsDropdown"
              optionLabel="xlabel"
              optionValue="xnavItemid"
              :filter="true"
              placeholder="Elige un enlace"
              :showClear="true"
              @before-show="navItemsDropdownFormateo(item)"
            >
              <template #option="slotProps">
                <div>
                  <div>
                    {{ slotProps.option.xnavItemid }} -
                    {{ slotProps.option.xlabel }}
                  </div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="col-12 d-flex align-items-center">
            <div class="p-field col-7">
              <label for="orden">Icono</label>
              <br /><i :class="item.xicon"></i>
              <InputText id="orden" type="text" v-model="item.xicon" />
            </div>
            <div class="p-field col-3">
              <label>Visible</label>
              <vs-switch v-model="item.xvisible" />
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button"
          @click="itemDialog = false"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          class="p-button"
          @click="actualizarItem"
        />
      </template>
    </Dialog>
  </section>
</template>

<script lang="js">
import NavbarService from '../../services/navbar.service.js';
  export default  {
    name: 'v-home-navbar',
    props: [],
    mounted () {
      this.getItems();
      this.getEnlaces();
    },
    data () {
      return {
        navbar:[],
        selectedItems:[],
        item :{},
        filters:{},
        itemDialog:false,
        borrarDialog:false,
        enlaces:[],
        navItemsDropdown:[],
        filasExpandidas:[],
        filasExpandidas2:[],

      }
    },
    methods: {
      navItemsDropdownFormateo(item){
        this.navItemsDropdown = this.navbar.filter(
          (navItem) => navItem.xnavItemid != item.xnavItemid
        );
        this.navbar.forEach((navItem)=>{
          if(navItem.items.length > 0 && navItem.xnavItemid != item.xnavItemid){
            this.navItemsDropdown = this.navItemsDropdown.concat(navItem.items)
          }
        })
        this.navItemsDropdown = this.navItemsDropdown.filter(
          (navItem) => navItem.xnavItemid != item.xnavItemid
        );
      },
      getItems(){
        NavbarService.getNavbar().then((response)=>{
          console.log(response.data)
          this.navbar =  response.data;
          this.navbar.forEach((item)=>{
            item.xvisible = item.xvisible == 1 ? true : false;
            NavbarService.getNavbarHijos(item.xnavItemid).then((response)=>{
              const itemsHijos = response.data;
              item.items = itemsHijos;
              if(item.items.length > 0){
                item.items.forEach((itemHijo)=>{
                  itemHijo.xvisible = itemHijo.xvisible == 1 ? true : false;
                  NavbarService.getNavbarHijos(itemHijo.xnavItemid).then((response)=>{
                    const itemsNietos = response.data;
                    itemHijo.items = itemsNietos;
                  })
                })//item.items.forEach((itemHijo)=>{
              }
            })// NavbarService.getNavbarHijos(item.xnavItemid).then((response)=>{
          })//this.itemsNavbar.forEach((item)=>{
        })//NavbarService.getNavbar().then((response)=>{
      },
      getEnlaces(){
        this.enlaces = [
          {xtitulo:'Home', xto:'/'}
        ];
        NavbarService.getEnlaces().then((response) => {
          this.enlaces=this.enlaces.concat(response.data[0]);
        });
      },
      borrarItems(){
        var arrayPromises = [];
        for(let i = 0; i < this.selectedItems.length; i++){
          arrayPromises.push(NavbarService.borrar(this.selectedItems[i].xnavItemid))
        }
        Promise.all(arrayPromises).then((response)=>{
          for(let i = 0; i < response.length; i++){
             this.$toast.add({
              severity: response[i].data.success ? 'info' : 'error',
              summary: response[i].data.success ? 'Eliminado': 'Error',
              detail: response[i].data.description,
              life: response[i].data.success ? 1000 : null
            })
          }
          this.borrarDialog = false;
          this.getItems();
          this.selectedItems.length = 0;
        }).catch((error)=>{
          this.$toast.add({
            severity: 'error',
            summary:'Error',
            detail: error
          })
        });
      },
      actualizarItem(){
        console.log(this.item)
        if(this.item.xnavItemid){
          NavbarService.update(this.item).then((response)=>{
          this.$toast.add({
            severity: response.data.success ? 'info' : 'error',
            summary: response.data.success ? 'Actualizado': 'Error',
            detail: response.data.description,
            life: response.data.success ? 3000 : null
          })
          this.itemDialog = false;
          this.getItems();
        }).catch((error)=>{
          this.$toast.add({
            severity: 'error',
            summary:'Error',
            detail: error
          })
        });
        }else{
          NavbarService.nuevo(this.item).then((response)=>{
          this.$toast.add({
            severity: response.data.success ? 'info' : 'error',
            summary: response.data.success ? 'Creado': 'Error',
            detail: response.data.description,
            life: response.data.success ? 3000 : null
          })
          this.itemDialog = false;
          this.getItems();
        }).catch((error)=>{
          this.$toast.add({
            severity: 'error',
            summary:'Error',
            detail: error
          })
        });
        }
      }
    },
    computed: {

    }
}
</script>

<style scoped lang="scss">
.v-home-navbar {
}
</style>
