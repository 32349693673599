import axios from 'axios';
import API_URL from '../backend'
import authHeader from './auth-header.service';

class NavbarService {
    getTodos(){
        const axiosRequest = {
            method: 'GET',
            url: API_URL+'/admin/navbar/todo',
            headers: authHeader()           
        }
        return axios(axiosRequest);
    }
    getNavbar(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/admin/navbar/navbar',
            headers: authHeader()    
        }
        return axios(axiosRequest);
    }
    getNavbarHijos(superId){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/admin/navbar/navbarHijos/'+superId,
            headers: authHeader()    
        }
        return axios(axiosRequest);
    }
    update(item){
        const axiosRequest = {
            method: 'POST',
            url: API_URL+'/admin/navbar/update',
            data:item,
            headers: authHeader()           
        }
        return axios(axiosRequest);
    }
    nuevo(item){
        const axiosRequest = {
            method: 'POST',
            url: API_URL+'/admin/navbar/nuevo',
            data:item,
            headers: authHeader()           
        }
        return axios(axiosRequest);
    }
    borrar(id){
        const axiosRequest = {
            method: 'DELETE',
            url: API_URL+'/admin/navbar/borrar/'+id,
            headers: authHeader()           
        }
        return axios(axiosRequest);
    }
    getEnlaces(){
        const axiosRequest = {
            method: 'GET',
            url: API_URL+'/admin/navbar/todosEnlaces',
            headers: authHeader()           
        }
        return axios(axiosRequest);
    }
}

export default new NavbarService();